<template>
    <div class="mainbox">
        <div class="detail_right">
            <div class="detail_right_til">校园风采</div>
            <div class="detail_list">
                <div v-for="(item, index) in lists" :key="index" class="detail_item">
                    <div class="detail_item_img">
                        <img :src="item.teacherImg" alt="">
                    </div>
                    <div class="detail_item_til">
                        <div class="elp"> {{ item.introduce }}</div>
                    </div>
                </div>
                <el-empty style="margin: 0 auto;" v-if="!lists || lists.length == 0" description="暂无数据"></el-empty>

            </div>
            <el-pagination background style="display: flex;justify-content: center;" :page-count="4"
                class="pagination partner-pagination" :current-page="pageNum" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange"
                @size-change="handleSizeChange" :page-sizes="[8, 12, 24]" />
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},

    data() {
        return {
            lists: [],

            pageSize: 8,
            pageNum: 1,
            total: 0,
        };
    },
    created() {
        this.id = this.$route.query.id;

        this.selectSchoolDemeanourPage()

    },
    mounted() {

    },
    methods: {
        selectSchoolDemeanourPage() {
            know.selectSchoolDemeanourPage({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                isDemeanour: 1,
                schoolId: this.id,
            })
                .then((res) => {
                    this.lists = res.rows
                    this.total = res.total
                });
        },
        mouseIn(item) {
            item.isShow = true
            this.$forceUpdate()
        },
        mouseOut(item) {
            item.isShow = false
            this.$forceUpdate()

        },
        handleSizeChange(val) {
            this.pageNum = 1
            this.pageSize = val
            this.selectSchoolDemeanourPage()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.selectSchoolDemeanourPage()
        },

    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;

    .detail_right {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        padding-bottom: 30px;

        .detail_right_til {
            height: 70px;
            text-align: center;
            line-height: 70px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 20px;
            color: #444444;
            font-weight: bold;
            border-bottom: 1px solid #DFDFDF;
            padding-left: 30px;
        }

        .detail_list {
            display: flex;
            align-items: center;
            padding: 30px;
            flex-wrap: wrap;

            .detail_item {
                width: 270px;
                height: 260px;
                background: #F3F4F8;
                border-radius: 4px 4px 4px 4px;
                margin-right: 20px;
                margin-bottom: 20px;
                overflow: hidden;

                .detail_item_img {
                    width: 270px;
                    height: 203px;
                }

                .detail_item_til {
                    width: 270px;
                    height: 60px;
                    background: #F3F4F8;
                    border-radius: 4px 4px 4px 4px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 16px;
                    color: #444444;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    >div {
                        width: 200px;
                        text-align: center;
                    }
                }


            }

            .detail_item:nth-child(4n) {
                margin-right: 0;

            }
        }
    }
}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>